<template>
  <v-card>
    <v-form @submit.prevent="changePassword" v-model="valid" @input="clear">
      <v-card-title>Change password</v-card-title>
      <v-card-text>
        <v-text-field
          :type="oldPasswordShow ? 'text' : 'password'"
          label="Old password"
          v-model="oldPassword"
          :rules="[rules.required, rules.minLenPass]"
          :append-icon="oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
          :hint="hint"
          counter
          @click:append="oldPasswordShow = !oldPasswordShow"
        />
        <v-text-field
          :type="newPasswordShow ? 'text' : 'password'"
          label="New password"
          v-model="newPassword"
          :rules="[rules.required, rules.minLenPass]"
          :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
          counter
          @click:append="newPasswordShow = !newPasswordShow"
        />
        <v-text-field
          :type="newPasswordRepeatShow ? 'text' : 'password'"
          label="Repeat new password"
          v-model="newPasswordRepeat"
          :rules="[rules.required, rules.minLenPass, rules.equalLenPass(newPassword)]"
          :append-icon="newPasswordRepeatShow ? 'mdi-eye' : 'mdi-eye-off'"
          counter
          @click:append="newPasswordRepeatShow = !newPasswordRepeatShow"
        />

      </v-card-text>
      <v-card-actions  class="flex-column">
        <v-row justify="center" align-content="center">
          <v-btn width="100px" type="submit" color="primary" :disabled="!valid" class="ma-2">Apply</v-btn>
          <v-btn width="100px" color="secondary" to="/" class="ma-2">Close</v-btn>
        </v-row>
        <v-row justify="center" align-content="center">
          <v-alert type="error" outlined v-model="showError">
            {{errorMessage}}
          </v-alert>
          <v-alert type="success" outlined v-model="showResult">
            Your password was changed successfully
          </v-alert>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { email, minLen, required } from '@/utils/validations';

export default {
  props: {
    username: String,
  },
  data() {
    return {
      valid: false,
      oldPassword: null,
      oldPasswordShow: false,
      newPassword: null,
      newPasswordShow: false,
      newPasswordRepeat: null,
      newPasswordRepeatShow: false,
      showError: false,
      showResult: false,
      errorMessage: null,
      rules: {
        required,
        email,
        minLen,
        minLenPass: minLen(6),
        equalLenPass: (password) => (v) => (v === password || 'You should repeat new password'),
      },
      hint: 'At least 6 symbols',
    };
  },
  methods: {
    async changePassword() {
      try {
        if (this.newPasswordRepeat !== this.newPassword) {
          this.errorMessage = 'Your entered different passwords';
          this.showError = true;
          return;
        }
        await this.$apiClient.changePassword(this.username, this.oldPassword, this.newPassword);
        this.showResult = true;
      } catch (e) {
        if (e.response?.status === 404) {
          this.errorMessage = 'Wrong old password. Please check and try again';
          this.showError = true;
        }
      }
    },
    clear() {
      this.showError = false;
      this.showResult = false;
    },
  },
};
</script>
