<template>
  <v-container style="width: 75vw" >
    <v-row dense>
      <v-col>
        <v-card class="ma-2">
          <v-card-title>User options </v-card-title>
          <v-card-text>
            <v-text-field dense
              label="First name"
              v-model="user.firstname"
              readonly
              outlined
            />
            <v-text-field dense
              label="Last name"
              v-model="user.lastname"
              readonly
              outlined
            />
            <v-text-field dense
              label="User name (e-mail)"
              v-model="user.username"
              readonly
              outlined
            />
          </v-card-text>
        </v-card>

        <UserSettings :user="user" class="ma-2" />
        <v-row justify="center" align-content="center" class="ma-5" flat>
          <v-btn class="ma-2" @click="() => (expand = !expand)">{{
            expand ? "Close" : "Set password"
          }}</v-btn>
        </v-row>
        <v-expand-transition>
          <div v-show="expand">
            <ChangePassword :username="userName" class="ma-2" />
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, minLen, required } from '@/utils/validations';
import ChangePassword from '@/views/profile/ChangePassword';
import UserSettings from '@/views/profile/UserSettings';

export default {
  name: 'Profile',
  components: { ChangePassword, UserSettings },
  computed: {
    ...mapGetters({ userName: 'userName', user: 'users/user' }),
  },
  data() {
    return {
      expand: false,
      rules: {
        required,
        email,
        minLen,
      },
    };
  },
  methods: {
    ...mapActions({ load: 'users/READ_user' }),
  },
  created() {
    if (this.userName) {
      this.load(this.userName);
    }
  },
};
</script>
