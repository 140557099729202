<template>
  <v-card>
    <v-form @submit.prevent="changeSettings" v-model="valid" @input="clear">
      <v-card-title>User settings</v-card-title>

      <v-card-text>
        <div v-for="(value,name) in user.userOptions" :key="value">
          <v-text-field dense
                        :label="name"
                        :value="value"
                        v-bind=name
                        outlined
          />
        </div>
      </v-card-text>
      <v-card-actions class="flex-column">
<!--        <div class="flex-column">-->
        <v-row justify="center" align-content="center" flat>
          <v-btn type="submit" color="primary" :disabled="true" class="ma-2">Apply</v-btn>
          <v-btn color="secondary" to="/" class="ma-2">Close</v-btn>
        </v-row>
        <v-row flat>
          <v-alert type="error" outlined v-model="showError">
            {{errorMessage}}
          </v-alert>
          <v-alert type="success" outlined v-model="showResult">
            Your password was changed successfully
          </v-alert>
        </v-row>
<!--        </div>-->
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { email, minLen, required } from '@/utils/validations';

export default {
  name: 'UserSettings',
  props: {
    user: Object,
  },
  data() {
    return {
      valid: false,
      settings: Object,
      showError: false,
      showResult: false,
      errorMessage: null,
      rules: {
        required,
        email,
        minLen,
        minLenPass: minLen(6),
        equalLenPass: (password) => (v) => (v === password || 'You should repeat new password'),
      },
      hint: 'At least 6 symbols',
    };
  },
  methods: {
    async changeSettings() {
      try {
        await this.$apiClient.changeUserSettings(this.username, this.user.userOptions);
        this.showResult = true;
      } catch (e) {
        if (e.response?.status === 404) {
          this.errorMessage = 'Please check and try again';
          this.showError = true;
        }
      }
    },
    clear() {
      this.showError = false;
      this.showResult = false;
    },
  },
};
</script>
